import React from 'react';
// import { graphql, Link, useStaticQuery } from 'gatsby';
import { useTranslation, Trans } from 'react-i18next';
import { useLocalization, LocalizedLink } from 'gatsby-theme-i18n';
import Layout from '../layouts/index';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEnvelope, faGlobeAmericas, faCheckDouble, faHandshake, faDatabase, faUserShield, faGavel, faFileSignature } from '@fortawesome/free-solid-svg-icons';
// import BlogArticle from '../components/Blog';
// import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import Slack01 from '../images/slack/slack-integration-01.png';
import Zoom1 from '../images/zoom/Zoom1.png';
import Zoom2 from '../images/zoom/Zoom2.png';
import Zoom3 from '../images/zoom/Zoom3.png';
import Zoom4 from '../images/zoom/Zoom4.png';
import Zoom5 from '../images/zoom/Zoom5.png';
import Zoom6 from '../images/zoom/Zoom6.png';
import Slack02 from '../images/slack/slack-integration-02.png';
import Slack2 from '../images/slack/slack-integration-2.jpg';
import Slack3 from '../images/slack/slack-integration-3.jpg';
import Slack4 from '../images/slack/slack-integration-4.jpg';
import Slack5 from '../images/slack/slack-integration-5.jpg';
import Slack7 from '../images/slack/slack-integration-7.jpg';
import Slack8 from '../images/slack/slack-integration-8.jpg';
// import Slack9 from '../images/slack/slack-integration-9.jpg';
// import Slack6 from '../images/slack/slack-integration-6.jpg';
// import Slack1 from '../images/slack/slack-integration-1.jpg';
// import Slack10 from '../images/slack/slack-integration-10.jpg';
// import Slack11 from '../images/slack/slack-integration-11.jpg';

function SlackIntegration({ location }) {
  const { t } = useTranslation();
  const { locale } = useLocalization();
  const zoom = {
    es: [
      {
        title: '1. Habilita la integración con Zoom',
        text:
          'Para habilitar Zoom debes ir a tu perfil y dentro de la pestaña de integraciones dar clic en Activar, el botón cambiará a verde.',
        image: Zoom1,
      },
      {
        title: '2. Permite que la aplicación de Trato se agregue a tu cuenta',
        text: 'Ahora deberás permitir que Trato pueda crear reuniones de Zoom.',
        image: Zoom2,
      },
      {
        title: '3. Una vez que has dado los permisos, regresa a TRATO',
      },
      {
        title:
          '4. Selecciona el documento que deseas vincular una llamada y habilita la opción de Zoom ',
        text: 'Selecciona en que documento quieres que se utilice Zoom',
        image: Zoom3,
      },
      {
        title: '5. Empieza la videollamada en Zoom',
        text:
          'Después de enviar a firma el documento podrás empezar la videollamada de Zoom',
        image: Zoom4,
      },
      {
        title: '6. Al Terminar la videollamada sube tu video',
        text:
          'Al Terminar la videollamada se te pedirá que subas el video que se generó, en caso de haberlo guardado de manera local',
        image: Zoom5,
      },
      {
        title:
          '7. Al finalizar la videollamada procede con la firma del documento',
        text:
          'Una vez que se se sube el video de la videollamada podrás proceder con la firma del documento',
        image: Zoom6,
      },
      {
        title: '8. Desactiva la integración',
        text: 'Al dar clic en activar de nuevo la integración se desactivará',
        image: Zoom1,
      },
      {
        title: '¿Necesitas ayuda?',
        text: `Contacta a nuestro equipo de soporte. <br /> <br />
        Envía un correo a soporte@trato.io o bien si te encuentras dentro de tu usuario de TRATO escríbenos en el chat ubicado en la esquina inferior izquierda. <br /> <br />
        Recuerda que el tiempo de respuesta es de 1 hora, sin embargo, de existir algún problema la solución definitiva podría tardar hasta 12 horas dependiendo la complejidad. <br/>`,
      },
    ],
    en: [
      {
        title: '1. Enable Zoom integration',
        text:
          'To enable Zoom you must go to your profile and within the integrations tab click on the gray button.',
        image: Zoom1,
      },
      {
        title: '2. Allow the Trato app to be added to your account',
        text: 'You will now need to allow Trato to create Zoom meetings',
        image: Zoom2,
      },
      {
        title: '3. Enable Zoom in your document',
        text: 'Select which document you want Zoom to be used on',
        image: Zoom3,
      },
      {
        title: '4. Start the video call on Zoom',
        text:
          'After sending the document for signature you can start the Zoom video call',
        image: Zoom4,
      },
      {
        title: '5. At the end of the video call upload your video',
        text:
          'At the end of the video call you will be asked to upload the video that was generated',
        image: Zoom5,
      },
      {
        title:
          '6. At the end of the video call, proceed with the signing of the document',
        text:
          'Once the video of the video call is uploaded, you can proceed with the signing of the document',
        image: Zoom6,
      },
      {
        title: '7. Disable the integration',
        text: 'By clicking on active again you disable the integration',
        image: Zoom1,
      },
      {
        title: 'Need help?',
        text: `Contact our support team.<br/> <br />
        Send an email to soporte@trato.io or if you are logged into your TRATO account, write us in the chat located in the lower left corner.<br /> <br />
        Remember that the response time is 1 hour, however, if there is a problem the final solution could take up to 12 hours depending on the complexity. <br />`,
      },
    ],
    pt: [
      {
        title: '1. Habilita la integración con Zoom',
        text:
          'Para habilitar Zoom debes ir a tu perfil y dentro de la pestaña de integraciones dar clic en el botón gris.',
        image: Zoom1,
      },
      {
        title: '2. Permite que la aplicación de Trato se agregue a tu cuenta',
        text: 'Ahora deberás permitir que Trato pueda crear reuniones de Zoom.',
        image: Zoom2,
      },
      {
        title: '3. Habilita Zoom en tu documento',
        text: 'Selecciona en que documento quieres que se utilice Zoom',
        image: Zoom3,
      },
      {
        title: '4. Empieza la videollamada en Slack',
        text:
          'Después de enviar a firma el documento podras empezar la videollamada de Zoom',
        image: Zoom4,
      },
      {
        title: '5. Al Terminar la videollamada tu video',
        text:
          'Al Terminar la videollamada se te pedirá que subas el video que se generó',
        image: Zoom5,
      },
      {
        title:
          '6. Al finalizar la videollamada procede con la firma del documento',
        text:
          'Una vez que se se sube el video de la videollamada podrás proceder con la firma del documento',
        image: Zoom6,
      },
      {
        title: '7. Desactiva la integración',
        text: 'Al dar clic en activar de nuevo la integración se desactivará',
        image: Zoom1,
      },
      {
        title: '¿Necesitas ayuda?',
        text: `
        Contacta a nuestro equipo de soporte. <br /> <br />
        Envía un correo a soporte@trato.io o bien si te encuentras dentro de tu usuario de TRATO escríbenos en el chat ubicado en la esquina inferior izquierda. <br />  <br />
        Recuerda que el tiempo de respuesta es de 1 hora, sin embargo, de existir algún problema la solución definitiva podría tardar hasta 12 horas dependiendo la complejidad. <br/>`,
      },
    ],
  };

  return (
    <Layout
      id="landing"
      locale={locale}
      bodyClass="page-home landing bg-grey"
      inverted
      border
      location={location}
    >
      <section>
        <div className="container" style={{ marginTop: '4rem' }}>
          <div className="card bg-light w-75 border rounded mx-auto">
            <div className="card-header p-4">
              <h1 className="font-weight-bold">{t('zoom.title')}</h1>
              <p className="lead text-muted">{t('zoom.subtitle')}</p>
              <p className="lead text-muted">
                <Trans
                  i18nKey="zoom.subtitle2"
                  components={{
                    Link: <LocalizedLink className="text-primary" />,
                  }}
                />
              </p>
            </div>
            <div className="card-body pt-2 px-4 pb-4">
              {zoom[locale].map((instruction, i) => (
                <div key={`ins-${locale}-${i}`} className="py-6 border-bottom">
                  <h5 className="font-weight-bold">{instruction.title}</h5>
                  <p
                    className="text-muted"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: instruction.text }}
                  />
                  {instruction.image && (
                    <img
                      src={instruction.image}
                      className="img-fluid rounded border mt-2"
                      alt="zoom"
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default SlackIntegration;
